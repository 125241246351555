import { api } from "./apiBase";

const url = "/v3/operation"; // Alterado para a versão 3 da API

export function getOperationsApi() {
  // Adicione o header X-Website-Type: crowdfunding a todas as requisições
  api.headers['X-Website-Type'] = 'crowdfunding';

  return api.get(`${url}/`);
}

export function getOperationsByCategoryApi(accessToken, id) {
  api.headers['X-Website-Type'] = 'crowdfunding';

  return api.get(`${url}/search/${id}/`);
}

export function getOperationDetailsApi(id) {
  api.headers['X-Website-Type'] = 'crowdfunding';

  return api.get(`${url}/${id}/`);
}