import styled from "styled-components";

export const DropdownContainer = styled.div`
	/* padding: 0 42px; */
	z-index: 2;
`;

export const DropdownButton = styled.div`
	padding: 12px;
	display: flex;
	cursor: pointer;
	// padding-top: 10px;
	align-items: center;
	border-radius: 10px;
	box-shadow: 0px 2px #70707070;

	background: #eeeeee;

	@media only screen and (min-width: 600px) {
		width: 200px;
	}
`;

export const TitleDiv = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const HelpTitle = styled.span`
	margin: 0;
	text-align: center;
	font-weight: 100px;
	font-size: 14px;
	margin-right: 10px;
	margin-left: 30px;
`;

export const DropdownArrowDiv = styled.div`
	margin-right: -7px;

	svg {
		height: 10px;
	}

	.arrow-right {
		transform: rotate(270deg);
		-webkit-transform: rotate(270deg);
		-moz-transform: rotate(270deg);
	}
`;

export const DropdownContent = styled.div`
	background: #f7f7f7;
	border-radius: 10px;
	padding: 2px 10px;
	margin-top: 2px;
`;
