import {
	OPERATIONS_GET_REDUCER,
	OPERATIONS_BY_CATEGORY_GET_REDUCER,
	OPERATION_DETAILS_GET_REDUCER,
	CLEAR_ALL_REDUCER
} from "../actions";

export const operationsListReducer = (state = null, action) => {
	switch (action.type) {
		case OPERATIONS_GET_REDUCER:
			return action.payload;
		case CLEAR_ALL_REDUCER:
			return null;
		default:
			return state;
	}
};

export const operationsListByCategoryReducer = (state = null, action) => {
	switch (action.type) {
		case OPERATIONS_BY_CATEGORY_GET_REDUCER:
			return action.payload;
		case CLEAR_ALL_REDUCER:
			return null;
		default:
			return state;
	}
};

export const operationDetailsReducer = (state = null, action) => {
	switch (action.type) {
		case OPERATION_DETAILS_GET_REDUCER:
			return action.payload;
		case CLEAR_ALL_REDUCER:
			return null;
		default:
			return state;
	}
};