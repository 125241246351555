import React, { useState } from "react";
import CardsCarousel from "./CardsCarousel";


import "./styles.scss";

/*const entriesArr = [
	{ text: "Aberto" },
	{ text: "Pré-reserva" },
	{ text: "Histórico" },
	{ text: "Tudo" },
];

/*const scrollsArr = [
	{ text: "Obras de Arte" },
	{ text: "Imobiliários" },
	{ text: "Empresariais" },
	{ text: "Judiciais" },
	{ text: "Royalities Musicais" },
];*/

function OpportunitiesContainer({ id }) {
	// const [activeEntry, setIsActiveEntry] = useState(0);
	// const [activeScroll, setIsActiveScroll] = useState(0);

	// const isActiveEntry = (n) => (n === activeEntry ? "active" : "");
	// const isActiveScroll = (n) => (n === activeScroll ? "active" : "");

	return (
		<div className="opportunities-container">
			<h2>Oportunidades</h2>

			<div>
				{/* ----------------- Entradas--------------------------- */}
				{/* <div className="entries-list">
					{entriesArr.map((item, index) => {
						return (
							<div
								key={index}
								className={`option ${isActiveEntry(index)}`}
								onClick={() => setIsActiveEntry(index)}
							>
								{item.text}
							</div>
						);
					})}
				</div> */}

				{/* ----------------- Filtro carousel ----------------- */}
				{/* <div className="scrolls-list">
					{scrollsArr.map((item, index) => {
						return (
							<div
								key={index}
								className={`scroll-option ${isActiveScroll(
									index
								)}`}
								onClick={() => setIsActiveScroll(index)}
							>
								{item.text}
							</div>
						);
					})}
				</div> */}

				<CardsCarousel id={id} />
			</div>
		</div>
	);
}

export default OpportunitiesContainer;
