import { all } from "redux-saga/effects";

import {
    watchGetOperationsAsync,
    watchGetOperationsByCategoryAsync,
    watchGetOperationDetailsAsync
} from './operationsSaga'


// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        watchGetOperationsAsync.call(),
        watchGetOperationsByCategoryAsync.call(),
        watchGetOperationDetailsAsync.call(),
    ])
}

export { clearStorage/*, getToken*/ } from "./authSaga";
