import React from "react";
import "./index.scss";

function index() {
	return (
		<div className="doubts-container">
			<div className="doubts-text">
				<h2 className="doubts-title">Dúvidas</h2>
				<p>
					Ficou com alguma dúvida ou quer começar a investir agora
					mesmo? Ao clicar no botão "Falar com o Especialista", você
					será direcionado para a nossa equipe de atendimento.
				</p>
			</div>
		</div>
	);
}

export default index;
