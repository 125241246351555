import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import EmptyListMessage from "../../Opportunities/components/OpportunitiesContainer/CardsCarousel/EmptyListMessage";
// import CardItem from "./CardItem";
// import { CardsContainer } from "./styles";
import Slider from "./Slider/index";

function CardsCarousel({ id, category, operations }) {
	return (
		<>
			{operations === null ? (
				<div className="loading">
					<CircularProgress style={{ color: "#01CC7F" }} />
				</div>
			) : (
				<>
					{operations.length ? (
						<Slider
							slides={operations}
							key={category}
							ind={category}
						/>
					) : (
						// <>
						// 	<CardsContainer listLength={operations.length}>
						// 		{operations.map((item, index) => {
						// 			return <CardItem key={index} item={item} />;
						// 		})}
						// 		<CardItem key={'0'} item={operations[0]} />
						// 	</CardsContainer>
						// </>
						<EmptyListMessage />
					)}
				</>
			)}
		</>
	);
}

export default CardsCarousel;
