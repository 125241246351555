export const categoriesArr = [
	{
		id: "HCP__WORKS_OF_ART",
		title: "Obras de arte",
	},
	{
		id: "HCP__REAL_STATE",
		title: "Imobiliários",
	},
	{
		id: "HCP__CORPORATE",
		title: "Empresariais",
	},
	{
		id: "HCP__JUDICIAL",
		title: "Judiciais",
	},
	{
		id: "HCP__MUSICAL_ROYALTIES",
		title: "Royalities Musicais",
	},
];