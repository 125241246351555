export const pageTextsArr = [
	{
		id: "judicial",
		title: "os Ativos Judiciais",
		description:
			`Os Ativos Judiciais são Operações que possuem Processos Judiciais em sua composição, onde podem ou não já terem sido "transitado em julgado", ou seja, encerrados. São exemplos de Ativos Judiciais, o Direito Creditório e o Precatório. Estas operações se baseiam na compra do ativo com desconto e na análise detalhada e individual dos processos até que o pagamento aos investidores ocorra, visto que quem investe nesta categoria adquire frações de cada um dos processos da rodada.`,
	},
	{
		id: "real-state",
		title: "as Incorporações Imobiliárias",
		description:
			"A Incorporação Imobiliária é a fase mais rentável do projeto imobiliário. O investimento já se inicia antes da venda do imóvel na planta, fazendo com que o investidor obtenha a valorização do metro quadrado desde o momento da idealização da construção do empreendimento imobiliário até a vendas das unidades. Nesta fase do imóvel, ocorre os processos de formalização do empreendimento e liberações dos alvarás necessários para a Construção.",
	},
	{
		id: "corporate",
		title: "os Ativos Empresariais",
		description:
			"Esta operação consiste em uma extensa análise de risco que é realizada em empresas que buscam crédito no mercado por algum motivo, como expansão ou investimento em tecnologia. O investidor empresta seu dinheiro a estas empresas e lucra com o juros.",
	},
	{
		id: "musical-royalties",
		title: "os Royalties Musicais",
		description:
			"Esta modalidade de investimentos é formada pela aquisição de recebíveis de Royalties (Direito Autoral) das obras e dos fonogramas (músicas) do catálogo adquirido. Os Royalties são recolhidos e distribuídos mensalmente para o detentor dos direitos. Do ponto de vista dos investidores, se adquire o Direito a Receber estes repasses mensais que aumentam conforme as músicas são reproduzidas.",
	},
	{
		id: "works-of-arts",
		title: "os ativos de Obras de Arte",
		description:
			"Investir em obras de arte já é algo antigo e conhecido. Nesta categoria de investimento, são escolhidas e adquiridas obras relevantes de artistas renomados onde a geração de receita dependerá da venda da obra em galerias ou em leilões de obras de arte. Cada investidor adquire uma fração das obras ao realizar seu aporte.",
	},
];