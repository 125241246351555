import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from 'react-router-dom';

import { getOperations } from "../../store/actions";

// import OpportunitiesByCategory from "./components/OpportunitiesByCategory";
// import DoubtsContainer from "../../components/common/DoubtsContainer";

import "./index.scss";
import PageHeader from "../../components/common/PageHeader";
import DropdownHelp from "./Dropdown";
import { arrStocks } from "./data";
// import CardsCarousel from "../Opportunities/components/OpportunitiesContainer/CardsCarousel";

import CardsCarousel from "./CardsCarousel";

function Home() {

	const dispatch = useDispatch();

	const operationsListReducer = useSelector(
		(state) => state.operationsListReducer
	);

	const [operations, setOperations] = useState(null);

	const getData = useCallback(async () => {
			await dispatch(getOperations());

		// if(id) {
			// const payload = { id };
			// await dispatch(getOperations(payload));
		// }
	}, [dispatch/*, id*/]);

	useEffect(() => {
		getData();
	}, [getData]);

	const getResponse = (res) => {
		setOperations(res);
	};

	useEffect(() => {
		if (operationsListReducer) {
			getResponse(operationsListReducer);
		}
	}, [dispatch, operationsListReducer]); 

	return (
		<div className="home">
			<PageHeader />

			<section className="section-1">
				<h1>Oportunidades por categoria</h1>

				{arrStocks.map(({ id, category, title, text, Icon }, ind) => {
					const key = category
					return (
						<React.Fragment key={category}>
							<DropdownHelp
								key={key}
								title={title}
								text={text}
								Icon={(s) => <Icon {...s} />}
							/>
							<CardsCarousel
							/*id={id}*/
							category={category}
							operations={operations && operations.filter((item) => item.category === category)}
							/>
						</React.Fragment>
					);
				})}
			</section>
			
			{/* 
			<div className="content">
				<h2>O que são Ativos Alternativos?</h2>

				<p>
					Os Ativos Alternativos/Ativos Reais, são oportunidades de
					investimentos que surgem na Economia Real, ou seja, geram
					riqueza não só para o investidor, como também para toda a
					sociedade.
				</p>

				// <Link to="/">Saiba Mais ...</Link>

				<OpportunitiesByCategory />

				<DoubtsContainer />
			</div> */}
		</div>
	);
}

export default Home;
