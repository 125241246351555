import { combineReducers } from "redux";

import {
	operationsListReducer,
	operationsListByCategoryReducer,
	operationDetailsReducer
} from './operationsReducer';

const rootReducer = combineReducers({
	operationsListReducer,
	operationsListByCategoryReducer,
	operationDetailsReducer
});

export default rootReducer;
