import React from "react";
import "./styles.scss";

function index() {
	return (
		<div className="answer-container">
			<div className="triangule-div">
				<div className='triangule'></div>
			</div>
			<div className="answer">
				Os Ativos Alternativos/Ativos Reais, são oportunidades de
				investimentos que surgem na Economia Real, ou seja, geram
				riqueza não só para o investidor, como também para toda a
				sociedade.
			</div>
		</div>
	);
}

export default index;
