import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOperationDetails } from "../../../../store/actions";

import { categoriesArr } from "./data";
import ClockIcon from "../../../../assets/svgs/opportunities/card/clock.svg";

import {
	StockCard,
	CoverImg,
	TopCardDiv,
	DaysLeftText,
	IRRText,
	CardBody,
	CardTitle,
	CardBottom,
	BottomTopDiv,
	DurationDiv,
	ValueBar,
	BarItem,
	ValuesDiv,
} from "./styles";
import { getDatesDiff, getLocaleNumber } from "./functions";

function CardItem({ item, ind }) {
	const dispatch = useDispatch();

	const operationDetailsReducer = useSelector(
		(state) => state.operationDetailsReducer
	);

	const { id } = item;
	const [details, setDetails] = useState(null);

	const [category, setCategory] = useState();
	const [IRR, setIRR] = useState("***");
	// const [minRaise, setMinRaise] = useState("***");

	const [daysLeft, setDaysLeft] = useState();
	const [amountPercent, setAmountPercent] = useState(0);

	const getData = useCallback(async () => {
		if (id) {
			const payload = { id };
			await dispatch(getOperationDetails(payload));
		}
	}, [dispatch, id]);

	useEffect(() => {
		getData();
	}, [getData]);

	const getResponse = useCallback((res) => {
		setDetails(res);
		const ausDays = getDatesDiff(item.endDate, item.startDate);
		setDaysLeft(ausDays);

		const auxAmountPer = ((item.amountConfirmed * 100) / item.goal).toFixed(
			2
		);

		setAmountPercent(auxAmountPer);
	}, [item.amountConfirmed, item.endDate, item.goal, item.startDate]);

	useEffect(() => {
		if (operationDetailsReducer) {
			getResponse(operationDetailsReducer);
		}
	}, [dispatch, getResponse, operationDetailsReducer]);

	const getCategoryData = (id) =>
		categoriesArr.find((item) => item.id === id);

	const updateValues = useCallback(() => {
		setCategory(getCategoryData(item.category));
		if (details && details.operation) {
			let { operation } = details;

			if (operation.scenarioInfo) {
				setIRR(operation.scenarioInfo.list.base.IRR);
			} else {
				// somente quando é ativo empresarial (corporate)
				setIRR(operation.estimatedIRR);
			}

			/*if (operation.minRaise) {
				setMinRaise(
					operation.minRaise.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})
				);
			} else {
				setMinRaise("R$ 10.000,00");
			}*/
		}
	}, [details, item.category]);

	useEffect(() => {
		updateValues();
	}, [item.category, updateValues]);

	return (
		<>
			{category && (
				<StockCard /*style={{ marginLeft: (ind === 0) ? 65 : 0 }}*/>
					<div>
						<TopCardDiv>
							<DaysLeftText>Faltam {daysLeft} dias</DaysLeftText>
							<IRRText>{IRR}% a.a</IRRText>
							{/* <IRRText>
								{category.id !== "HCP__MUSICAL_ROYALTIES" &&
									"IPCA  + "}{" "}
								{IRR}% a.a
							</IRRText> */}
						</TopCardDiv>
						<CoverImg src={item.coverImage.low} alt="cover" />
					</div>

					<CardBody>
						<CardTitle>{item.name}</CardTitle>

						<CardBottom>
							<BottomTopDiv>
								<span>{category.title}</span>

								<DurationDiv>
									<img
										src={ClockIcon}
										alt="tempo"
										style={{ marginRight: 3 }}
									/>
									<span>{item.estimatedDuration} meses</span>
								</DurationDiv>
							</BottomTopDiv>

							<ValueBar>
								<BarItem
									style={{ width: `${amountPercent}%` }}
								/>
							</ValueBar>
							<ValuesDiv>
								<span>{`${Math.round(amountPercent)}`}% captados</span>
								<span>{getLocaleNumber(item.goal)} BRL</span>
							</ValuesDiv>
						</CardBottom>
					</CardBody>
				</StockCard>
			)}
		</>
	);
}

export default CardItem;
