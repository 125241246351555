import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOperationDetails } from "../../../../../store/actions";

// import CardImage from "../../../../../assets/images/opportunities/top-card.png";
import LogoHurst from "../../../../../assets/svgs/opportunities/card/logo-hurst.svg";

import { ReactComponent as ArtIcon } from "../../../../../assets/svgs/opportunities/art-assets.svg";
import { ReactComponent as RealEstateIcon } from "../../../../../assets/svgs/opportunities/real-estate.svg";
import { ReactComponent as BusinessIcon } from "../../../../../assets/svgs/opportunities/business.svg";
import { ReactComponent as JudicialStocksIcon } from "../../../../../assets/svgs/opportunities/judicial-stocks.svg";
import { ReactComponent as MusicalsIcon } from "../../../../../assets/svgs/opportunities/musicals.svg";

import ClockIcon from "../../../../../assets/svgs/opportunities/card/clock.svg";

const categoriesArr = [
	{
		id: "HCP__WORKS_OF_ART",
		icon: <ArtIcon />,
		title: "Obras de arte",
	},
	{
		id: "HCP__REAL_STATE",
		icon: <RealEstateIcon />,
		title: "Imobiliários",
	},
	{
		id: "HCP__CORPORATE",
		icon: <BusinessIcon />,
		title: "Empresariais",
	},
	{
		id: "HCP__JUDICIAL",
		icon: <JudicialStocksIcon />,
		title: "Judiciais",
	},
	{
		id: "HCP__MUSICAL_ROYALTIES",
		icon: <MusicalsIcon />,
		title: "Royalities Musicais",
	},
];

function CardItem({ item }) {
	const dispatch = useDispatch();

	const operationDetailsReducer = useSelector(
		(state) => state.operationDetailsReducer
	);

	const { id } = item;
	const [details, setDetails] = useState(null);

	const [category, setCategory] = useState();
	const [IRR, setIRR] = useState("***");
	const [minRaise, setMinRaise] = useState("***");

	const getData = useCallback(async () => {
		if (id) {
			const payload = { id };
			await dispatch(getOperationDetails(payload));
		}
	}, [dispatch, id]);

	useEffect(() => {
		getData();
	}, [getData]);

	const getResponse = (res) => {
		setDetails(res);
	};

	useEffect(() => {
		if (operationDetailsReducer) {
			getResponse(operationDetailsReducer);
		}
	}, [dispatch, operationDetailsReducer]);

	const getCategoryData = (id) =>
		categoriesArr.find((item) => item.id === id);

	const updateValues = useCallback(() => {
		setCategory(getCategoryData(item.category));
		if (details && details.operation) {
			let { operation } = details;

			if (operation.scenarioInfo) {
				setIRR(operation.scenarioInfo.list.base.IRR);
			} else {
				// somente quando é ativo empresarial (corporate)
				setIRR(operation.estimatedIRR);
			}

			if (operation.minRaise) {
				setMinRaise(
					operation.minRaise.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})
				);
			} else {
				setMinRaise("R$ 10.000,00");
			}
		}
	}, [details, item.category]);

	useEffect(() => {
		updateValues();
	}, [item.category, updateValues]);

	// console.warn('details:')
	// console.log(details)

	return (
		<div className="card" /*onClick={() => alert("card clicked")}*/>
			{category && (
				<>
					<div
						className="top-card"
						style={{
							backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
					url(${item.coverImage.low})`,
						}}
					>
						{/* <img src={item.coverImage.low} className="card-bg-img" alt="imagem de funco"/> */}

						<div className="top-card-content">
							<img
								className="logo"
								src={LogoHurst}
								alt="logo Hurst"
							/>
							<span>{item.name}</span>
						</div>
					</div>
					<div className="card-body">
						<div className="info-1">
							<div className="icon-div">
								{category.icon}
								{/* <img src={category.icon} alt="ícone arte" /> */}
								<span>{category.title}</span>
							</div>
							<div className="icon-div">
								<img src={ClockIcon} alt="tempo" />
								<span>{item.estimatedDuration} meses</span>
							</div>
						</div>

						<div className="info-2">
							<div>
								<span>Taxa:</span>
								<span>Prazo de captação:</span>
								<span>Valor mínimo:</span>
							</div>
							<div>
								<strong>
									{category.id !== "HCP__MUSICAL_ROYALTIES" &&
										"IPCA  + "}{" "}
									{IRR}%
								</strong>
								<strong>{item.estimatedDuration} meses</strong>
								<strong>{minRaise}</strong>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default CardItem;
