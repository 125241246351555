import React from "react";

import EmptyListIcon from "../../../../../../assets/svgs/opportunities/card/sad-face.svg";
import "./styles.scss";

function EmptyListMessage() {
	return (
		<div className="empty-list-message">
			<img src={EmptyListIcon} alt="ícone lista vazia" height="31.98" />
			<span>
				Desculpe! Parece que não existem oportunidades abertas nessa
				categoria
			</span>
		</div>
	);
}

export default EmptyListMessage;
