import {
  OPERATIONS_GET_SAGA,
  OPERATIONS_BY_CATEGORY_GET_SAGA,
  OPERATION_DETAILS_GET_SAGA,
} from "./index";

export function getOperations(payload) { // get all
	return {
		type: OPERATIONS_GET_SAGA,
		payload,
	};
}

export function getOperationsByCategory(payload) {
	return {
		type: OPERATIONS_BY_CATEGORY_GET_SAGA,
		payload,
	};
}

export function getOperationDetails(payload) {
	return {
		type: OPERATION_DETAILS_GET_SAGA,
		payload,
	};
}
