import React from "react";
import { useParams } from "react-router-dom";

import PageHeader from "../../components/common/PageHeader";
import OpportunitiesContainer from "./components/OpportunitiesContainer";
import DoubtsContainer from "../../components/common/DoubtsContainer";
// import "./index.scss";
import { pageTextsArr } from './pageTexts'

function Opportunities() {
	const { id } = useParams();
	let item = pageTextsArr.find((item) => item.id === id);

	if(!item) {
		item = {
			title: '<Título>',
			description: '(ainda não possui descrição)',
		}
	}

	const { title, description } = item

	return (
		<div>
			<PageHeader title='Oportunidades' />
			<div className="content">
				<h2>Como funcionam {title}?</h2>
				<p>{description}</p>
				{/* <Link to="/">Saiba Mais ...</Link> */}
				<OpportunitiesContainer id={id} />
				<DoubtsContainer />
			</div>
		</div>
	);
}

export default Opportunities;
