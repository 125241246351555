export const OPERATIONS_GET_SAGA = "OPERATIONS_GET_SAGA";
export const OPERATIONS_GET_REDUCER = "OPERATIONS_GET_REDUCER";

export const OPERATIONS_BY_CATEGORY_GET_SAGA = "OPERATIONS_BY_CATEGORY_GET_SAGA";
export const OPERATIONS_BY_CATEGORY_GET_REDUCER = "OPERATIONS_BY_CATEGORY_GET_REDUCER";

export const OPERATION_DETAILS_GET_SAGA = "OPERATION_DETAILS_GET_SAGA";
export const OPERATION_DETAILS_GET_REDUCER = "OPERATION_DETAILS_GET_REDUCER";

export const CLEAR_ALL_REDUCER = "CLEAR_ALL_REDUCER";

export {
	getOperations,
	getOperationsByCategory,
	getOperationDetails,
} from "./operationsAction";
