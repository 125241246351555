import styled from "styled-components";

export const StockCard = styled.div`
	background-color: #fffefe;
	min-height: 330px;
	/* min-width: 276px;
	max-width: 276px;
	height: 330px;
	margin-right: 20px;
	margin-top: 13px; */
	padding: 10px;
	box-sizing: border-box;
	box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.1);
`;

export const CoverImg = styled.img`
	width: 100%;
	height: 176px;
	/* height: auto; */
	/* max-height: 205px; */
    margin-top: -31px;
`;

export const TopCardDiv = styled.div`
	display: flex;
	justify-content: space-between;

	position: relative;
	top: 7px;
`;

export const DaysLeftText = styled.span`
	position: relative;
	left: 7px;
	background-color: #01e27f;
	font-size: 10px;
	padding: 10px;
	border-radius: 10px;
	color: #fff;
`;

export const IRRText = styled.span`
	position: relative;
	right: 7px;
	background-color: #00a2d4;
	font-size: 10px;
	padding: 10px;
	border-radius: 10px;
	color: #fff;
`;

export const CardBody = styled.div``;

export const CardTitle = styled.div`
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	margin: 0 10px;
	margin-bottom: 17px;
`;

export const CardBottom = styled.div``;

export const BottomTopDiv = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 12px;
`;

export const DurationDiv = styled.div`
	display: flex;
`;

export const ValueBar = styled.div`
	display: flex;
	flex: 1 1;
	width: 100%;
	margin: 5px 0;
	border-radius: 10px;
	background-color: #a4a5a5;
`;

export const BarItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 11px;
	color: #fff;
	font-size: 11px;
	border-radius: 20px;

	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: #01d37f;
	/* width: 94.7256%; */
`;

export const ValuesDiv = styled.div`
	display: flex;
	justify-content: space-between;

	font-size: 12px;
	font-weight: 600;
`;
