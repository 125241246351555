import { call, put, takeLeading } from "redux-saga/effects";

import {
    OPERATIONS_GET_SAGA,
    OPERATIONS_GET_REDUCER,
	OPERATIONS_BY_CATEGORY_GET_SAGA,
	OPERATIONS_BY_CATEGORY_GET_REDUCER,
	OPERATION_DETAILS_GET_SAGA,
	OPERATION_DETAILS_GET_REDUCER
} from "../actions";

import {
	getOperationsApi,
	getOperationDetailsApi,
	getOperationsByCategoryApi
} from "../apis";

import { clearStorage/*, getToken*/ } from "./index";

export function* watchGetOperationsAsync() {
	yield takeLeading(OPERATIONS_GET_SAGA, getOperations);
}

export function* watchGetOperationsByCategoryAsync() {
	yield takeLeading(OPERATIONS_BY_CATEGORY_GET_SAGA, getOperationsByCategory);
}


export function* watchGetOperationDetailsAsync() {
	yield takeLeading(OPERATION_DETAILS_GET_SAGA, getOperationDetails);
}

export function* getOperations() {
	try {
		// let token = yield call(getToken);
		const response = yield call(getOperationsApi/*, token*/);

		if (response.ok) {
			const payload = response ? response.data.data : null;
			yield put({ type: OPERATIONS_GET_REDUCER, payload: payload });
		} else {
			yield call(clearStorage);

			// switch (response.status) {
			// 	case 401:
			// 		yield call(clearStorage);
			// 		break;
			// 	default:
			// 		break;
			// }
		}
	} catch (error) {
		console.log(error);
	}
}


export function* getOperationsByCategory(action) {
	try {
		// let token = yield call(getToken);
		const response = yield call(getOperationsByCategoryApi/*, token*/, action.payload.id);

		if (response.ok) {
			const payload = response ? response.data.data : null;
			yield put({ type: OPERATIONS_BY_CATEGORY_GET_REDUCER, payload: payload });
		} else {
			yield call(clearStorage);

			// switch (response.status) {
			// 	case 401:
			// 		yield call(clearStorage);
			// 		break;
			// 	default:
			// 		break;
			// }
		}
	} catch (error) {
		console.log(error);
	}
}

export function* getOperationDetails(action) {
	try {
		// let token = yield call(getToken);
		const response = yield call(getOperationDetailsApi/*, token*/, action.payload.id);

		if (response.ok) {
			const payload = response ? response.data.data : null;
			yield put({ type: OPERATION_DETAILS_GET_REDUCER, payload: payload });
		} else {
			yield call(clearStorage);

			// switch (response.status) {
			// 	case 401:
			// 		yield call(clearStorage);
			// 		break;
			// 	default:
			// 		break;
			// }
		}
	} catch (error) {
		console.log(error);
	}
}
