import React from "react";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import "./App.scss";
import Home from "./pages/Home";
import Opportunities from "./pages/Opportunities";

function App() {
	return (
		<Router basename={""} /*history={history}*/>
			<Switch>
				<Route path="/"  exact component={Home} />
				<Route path="/opportunities/:id" component={Opportunities} />
			</Switch>
		</Router>
	);
}

export default App;
