import React, { useState } from "react";

// import { ReactComponent as ArroLeftIcon } from "../../../assets/svgs/arrow-left.svg";
import { ReactComponent as DudeIcon } from "../../../assets/svgs/dude-icon.svg";
import Answer from "./Answer";

import "./styles.scss";

function PageHeader() {
	const [answerOpen, setAnswerOpen] = useState(false);

	const handleAnswer = () => setAnswerOpen(!answerOpen);

	return (
		<>
			<div className="page-header">
				
				<div className="title-container">
					<span className="page-title">Ativos Alternativos</span>
					<button onClick={handleAnswer} className='dude-btn'>
						<DudeIcon className="dude-icon" />
					</button>
				</div>
			</div>

			{answerOpen && <Answer />}
		</>
	);
}

export default PageHeader;
