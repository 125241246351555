import { ReactComponent as JudicialIcon } from "../../assets/svgs/stocks/judicial-icon.svg";
import { ReactComponent as ReaEstateIcon } from "../../assets/svgs/stocks/real-state-icon.svg";
import { ReactComponent as ArtIcon } from "../../assets/svgs/stocks/art-icon.svg";
import { ReactComponent as MusicalIcon } from "../../assets/svgs/stocks/musical-icon.svg";
import { ReactComponent as BusinessIcon } from "../../assets/svgs/stocks/business-icon.svg";

export const arrStocks = [
	{
		id: "judicial",
		category: "HCP__JUDICIAL",
		title: "Judiciais",
		text: `Os Ativos Judiciais são Operações que possuem Processos Judiciais em sua composição, 
		onde podem ou não já terem sido "transitado em julgado", ou seja, encerrados. 
		São exemplos de Ativos Judiciais, o Direito Creditório e o Precatório. 
		Estas operações se baseiam na compra do ativo com desconto e na análise detalhada e individual dos processos até que o pagamento aos investidores ocorra, 
		visto que quem investe nesta categoria adquire frações de cada um dos processos da rodada.`,
		Icon: JudicialIcon,
	},
	{
		id: "real-state",
		category: "HCP__REAL_STATE",
		title: "Imobiliários",
		text: `A Incorporação Imobiliária é a fase mais rentável do projeto imobiliário. O investimento já se inicia antes da venda do imóvel na planta, fazendo com que o investidor obtenha a valorização do metro quadrado desde o momento da idealização da construção do empreendimento imobiliário até a vendas das unidades. Nesta fase do imóvel, ocorre os processos de formalização do empreendimento e liberações dos alvarás necessários para a Construção.`,
		Icon: ReaEstateIcon,
	},
	{
		id: "works-of-arts",
		category: "HCP__WORKS_OF_ART",
		title: "Arte",
		text: `Investir em obras de arte já é algo antigo e conhecido. Nesta categoria de investimento, são escolhidas e adquiridas obras relevantes de artistas renomados onde a geração de receita dependerá da venda da obra em galerias ou em leilões de obras de arte. Cada investidor adquire uma fração das obras ao realizar seu aporte.`,
		Icon: ArtIcon,
	},
	{
		id: "musical-royalties",
		category: "HCP__MUSICAL_ROYALTIES",
		title: "Musicais",
		text: `Esta modalidade de investimentos é formada pela aquisição de recebíveis de Royalties (Direito Autoral) das obras e dos fonogramas (músicas) do catálogo adquirido. Os Royalties são recolhidos e distribuídos mensalmente para o detentor dos direitos. Do ponto de vista dos investidores, se adquire o Direito a Receber estes repasses mensais que aumentam conforme as músicas são reproduzidas.`,
		Icon: MusicalIcon,
	},
	{
		id: "corporate",
		category: "HCP__CORPORATE",
		title: "Empresarial", // corporate
		text: `Esta operação consiste em uma extensa análise de risco que é realizada em empresas que buscam crédito no mercado por algum motivo, como expansão ou investimento em tecnologia. O investidor empresta seu dinheiro a estas empresas e lucra com o juros.`,
		Icon: BusinessIcon,
	},
];