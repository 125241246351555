import React from "react";
import CardItem from "../CardItem";
import "./styles.scss";

// =========================
// Slide
// =========================

class Slide extends React.Component {
	constructor(props) {
		super(props);

		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleSlideClick = this.handleSlideClick.bind(this);
		this.imageLoaded = this.imageLoaded.bind(this);
		this.slide = React.createRef();
	}

	handleMouseMove(event) {
		const el = this.slide.current;
		const r = el.getBoundingClientRect();

		el.style.setProperty(
			"--x",
			event.clientX - (r.left + Math.floor(r.width / 2))
		);
		el.style.setProperty(
			"--y",
			event.clientY - (r.top + Math.floor(r.height / 2))
		);
	}

	handleMouseLeave(event) {
		this.slide.current.style.setProperty("--x", 0);
		this.slide.current.style.setProperty("--y", 0);
	}

	handleSlideClick(event) {
		this.props.handleSlideClick(this.props.index);
		// this.props.handleSlideClick(this.props.slide.index);
	}

	imageLoaded(event) {
		event.target.style.opacity = 1;
	}

	render() {
		// const { src, button, headline, } = this.props.slide;
		const { index } = this.props;

		const current = this.props.current;
		let classNames = "slide";

		if (current === index) classNames += " slide--current";
		else if (current - 1 === index) classNames += " slide--previous";
		else if (current + 1 === index) classNames += " slide--next";

		const screenWidth = window.screen.width;

		return (
			<li
				ref={this.slide}
				className={classNames}
				onClick={this.handleSlideClick}
				onMouseMove={this.handleMouseMove}
				onMouseLeave={this.handleMouseLeave}
				style={
					screenWidth < 400
						? index === 0
							? { marginLeft: -20 }
							: index === (this.props.arrLength -1) ? { marginRight: -56 } : null
						: null
				}
			>
				<CardItem key={index} item={this.props.slide} />
				{/* <div className="slide__image-wrapper">
					<img
						className={"slide__image"}
						alt={headline}
						src={src}
						onLoad={this.imageLoaded}
					/>
				</div> */}
			</li>
		);
	}
}

// =========================
// Slider control
// =========================

const SliderControl = ({ type, title, handleClick }) => {
	return (
		<button
			className={`btn btn--${type}`}
			title={title}
			onClick={handleClick}
		>
			<svg className={"icon"} viewBox={"0 0 24 24"}>
				<path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
			</svg>
		</button>
	);
};

// =========================
// Slider
// =========================

export default class Slider extends React.Component {
	constructor(props) {
		super(props);

		this.state = { current: 0 };
		this.handlePreviousClick = this.handlePreviousClick.bind(this);
		this.handleNextClick = this.handleNextClick.bind(this);
		this.handleSlideClick = this.handleSlideClick.bind(this);
	}

	handlePreviousClick() {
		const previous = this.state.current - 1;

		this.setState({
			current: previous < 0 ? this.props.slides.length - 1 : previous,
		});
	}

	handleNextClick() {
		const next = this.state.current + 1;
		this.setState({
			current: next === this.props.slides.length ? 0 : next,
		});
	}

	handleSlideClick(index) {
		if (this.state.current !== index) {
			this.setState({
				current: index,
			});
		}
	}

	render() {
		const { current/*, direction*/ } = this.state;
		const { slides/*, heading*/ } = this.props;
		// const headingId = `slider-heading__${heading
		// 	.replace(/\s+/g, "-")
		// 	.toLowerCase()}`;
		const wrapperTransform = {
			transform: `translateX(-${current * (100 / slides.length)}%)`,
		};
		return (
			<div className="slider" /*aria-labelledby={headingId}*/>
				<ul className="slider__wrapper" style={wrapperTransform}>
					{/* <h3 id={headingId} class="visuallyhidden">
						{heading}
					</h3> */}

					{slides.map((slide, index) => {
						const length = slides.length
						return (
							<Slide
								key={`${this.props.ind}-${index}`}
								index={index}
								slide={slide}
								current={current}
								arrLength={length}
								handleSlideClick={this.handleSlideClick}
							/>
						);
					})}
				</ul>

				<div className="slider__controls">
					<SliderControl
						type="previous"
						title="Vai para o slide anterior" // Go to previous slide
						handleClick={this.handlePreviousClick}
					/>

					<SliderControl
						type="next"
						title="Vai para o próximo slide" // Go to next slide
						handleClick={this.handleNextClick}
					/>
				</div>
			</div>
		);
	}
}
