import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getOperationsByCategory } from "../../../../../store/actions";

import EmptyListMessage from "./EmptyListMessage";
import CardItem from "./CardItem";
import "./styles.scss";

function CardsCarousel({ id }) {
	const dispatch = useDispatch();

	const operationsListByCategoryReducer = useSelector(
		(state) => state.operationsListByCategoryReducer
	);

	const [operations, setOperations] = useState(null);

	const getData = useCallback(async () => {
		if(id) {
			const payload = { id };
			await dispatch(getOperationsByCategory(payload));
		}
	}, [dispatch, id]);

	useEffect(() => {
		getData();
	}, [getData]);

	const getResponse = (res) => {
		setOperations(res);
	};

	useEffect(() => {
		if (operationsListByCategoryReducer) {
			getResponse(operationsListByCategoryReducer);
		}
	}, [dispatch, operationsListByCategoryReducer]); 

	return (
		<>
			{operations === null ? (
				<div className="loading">
					<CircularProgress style={{ color: "#01CC7F" }} />
				</div>
			) : (
				<>
					{operations.length ? (
						<div className="cards-list">
							{operations.map((item, index) => {
								return <CardItem key={index} item={item} />;
							})}
						</div>
					) : (
						<EmptyListMessage />
					)}
				</>
			)}
		</>
	);
}

export default CardsCarousel;
