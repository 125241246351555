import React from "react";
import { ReactComponent as ArrowDown } from "../../../assets/svgs/arrow-down.svg";

import {
    DropdownContainer,
    DropdownButton,
    TitleDiv,
    HelpTitle,
    DropdownArrowDiv,
    DropdownContent
} from "./styles";

function DropdownHelp({ title, text, Icon }) {
	const [showHelp, setShowHelp] = React.useState(false);

	return (
		<DropdownContainer>
			<DropdownButton
				onClick={() => setShowHelp(!showHelp)}
			>
				<TitleDiv>
					<HelpTitle>{title}</HelpTitle>
					<Icon height={20} width={20} />
				</TitleDiv>
				<DropdownArrowDiv>
					{showHelp ? (
						<ArrowDown />
					) : (
						<ArrowDown className="arrow-right" />
					)}
				</DropdownArrowDiv>
			</DropdownButton>

			{showHelp && (
				<DropdownContent>
					<p>{text}</p>
                </DropdownContent>
			)}
		</DropdownContainer>
	);
}

export default DropdownHelp;
