export const getDatesDiff = (startDate, endDate) => {
	// const example = {
	//     "startDate": "2021-11-04T00:00:00.000Z",
	//         "endDate": "2021-12-07T02:59:00.000Z"
	//     }

	const data1 = new Date(startDate);
	const data2 = new Date(endDate);

	const diff = Math.abs(data1.getTime() - data2.getTime());

	const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
	// console.log('days: ', days)
	// 1000 é o número de milisegundos em 1 segundo
	// 60 segundos tem um minuto
	// 60 minutos tem uma hora ?
	// 24 horas tem um dia ??

	// 1.a Multiplique tudo e você obtem o número de milisegundos em um dia.
	// 1.b Use essa constante se preferir 86400000.
	// 2. Divida pela diferença entre datas e você tem o número de dias
	return days;
};

export const getLocaleNumber = (valor) => {
	let newV = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	newV = newV.replace('R$ ', '');
	return newV.replaceAll('.', ' ')
}